<template>
    <div class="login">
        <div class="loginIn">
            <img class="loginInImg" src="../assets/images/denglubeijing.png" alt="">
            <div id="login_container">
                <div class="login_container_title" v-if="status != 5">
                    <span>微信扫码登陆</span>
                </div>
                <div class="login_container_msg">
                    <template v-if="status == 0">
                        <el-image class="login_container_img" :src="imgSrc">
                            <template #error>
                                <div class="image-slot">
                                    <el-icon class="is-loading" color="#bbb" size="30">
                                        <Loading/>
                                    </el-icon>
                                </div>
                            </template>
                        </el-image>
                        <div class="login_container_text">
                            <span>请使用微信扫码登录</span>
                        </div>
                        <div class="login_container_text">
                            <span></span>
                        </div>
                    </template>
                    <template v-if="status == 1">
                        <el-result icon="info" title="已扫码" subTitle="正在进行操作"></el-result>
                        <!--<div class="login_container_text">-->
                        <!--<span>已扫码</span>-->
                        <!--</div>-->
                    </template>
                    <template v-if="status == 2">
                        <el-result icon="success" title="登陆成功" subTitle="正在跳转"></el-result>
                        <!--<div class="login_container_text">-->
                        <!--<span>登陆成功</span>-->
                        <!--</div>-->
                    </template>
                    <template v-if="status == 3">
                        <el-result icon="warning" title="已过期" subTitle="请刷新">
                            <template #extra>
                                <el-button type="primary" size="medium" @click="getImg">刷新</el-button>
                            </template>
                        </el-result>
                        <!--<div class="login_container_text">-->
                        <!--<span>已过期</span>-->
                        <!--</div>-->
                    </template>
                    <template v-if="status == 4">
                        <el-result icon="warning" title="已扫码" subTitle="用户拒绝">
                            <template #extra>
                                <el-button type="primary" size="medium" @click="getImg">刷新</el-button>
                            </template>
                        </el-result>
                        <!--<div class="login_container_text">-->
                        <!--<span>已取消登录</span>-->
                        <!--</div>-->
                    </template>
                    <template v-if="status == 5">
                        <el-card class="box-card">
                            <template #header>
                                <div class="card-header">
                                    <span>请选择要登陆的身份</span>
                                </div>
                            </template>
                            <div v-for="item in loginList" :key="item.card_number" class="text item">
                                <el-link type="primary" @click="selLoginOne(item)">{{item.name}}
                                    ({{item.card_number}})
                                </el-link>
                            </div>
                        </el-card>
                    </template>
                    <template v-if="status == 6">
                        <el-result icon="warning" title="已扫码" subTitle="暂无可登陆用户">
                            <template #extra>
                                <el-button type="primary" size="medium" @click="getImg">刷新</el-button>
                            </template>
                        </el-result>
                    </template>
                    <template v-if="status == 7">
                        <el-result icon="info" title="登陆中" subTitle="正在获取信息..."></el-result>
                        <!--<div class="login_container_text">-->
                        <!--<span>已扫码</span>-->
                        <!--</div>-->
                    </template>
                    <template v-if="status == 8">
                        <el-result icon="warning" title="登陆失败" :subTitle="secretMsg">
                        </el-result>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {ElLoading} from 'element-plus'

    import Global from '../Global.js'

    import {businessPost, businessGet} from '../api/business.js'

    import {Loading} from '@element-plus/icons'

    export default {
        name: "login",
        components: {
            Loading
        },
        data() {
            return {
                imgSrc: '',
                ident_code: '',
                status: 0,
                openid: '',
                loginList: [],
                card_number: '',
                secretMsg:'',
            }
        },
        created() {
            document.title = '登陆' + 'v' + Global.GlobalVersion;
            if (localStorage.getItem(Global.GlobalLocalName2)) {
                localStorage.removeItem(Global.GlobalLocalName2)
            }
            if (this.$route.query.secret) {
                this.status = 7;
                this.getSecretMsg();
            }
            else {
                this.getImg();
            }

            // this.$nextTick(() => {
            // let timestamp = Date.parse(new Date());
            // let obj = new WxLogin({
            //     self_redirect: true,
            //     id: "login_container",
            //     appid: process.env.VUE_APP_API_APPID,
            //     scope: "snsapi_login",
            //     redirect_uri: encodeURIComponent("http://xfx.beta.51xiaoju.cn/xzt_saas_school_manage/#/auth?a=" + timestamp),
            //     state: location.origin,
            //     style: "",
            //     href: ""
            // });
            // console.log(obj);
            // setTimeout(() => {
            //     window.addEventListener('message', event => {
            //         console.log('收到参数', event);
            //         this.msg = event.data;
            //         this.login();
            //     })
            // }, 2000)
            // })
        },
        methods: {
            getSecretMsg() {
                let url = '/common/v1/weixiao/verify?secret='+this.$route.query.secret;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.openid = res.data.data.openid;
                        this.getLoginList();
                    }
                    else {
                        this.secretMsg = res.data.message;
                        this.status = 8;
                    }
                })
            },
            selLoginOne(val) {
                this.status = 2;
                this.card_number = val.card_number;
                this.getUserInfo();
            },
            getLoginList() {
                let url = '/admin/v1/wechat/user/list?openid=' + this.openid;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let loginList = [];
                        res.data.data.forEach(item => {
                            if (item.type == 2) {
                                loginList.push(item)
                            }
                        })
                        this.loginList = loginList;
                        if (this.loginList.length == 0) {
                            this.status = 6;
                        }
                        else if (this.loginList.length == 1) {
                            this.status = 2;
                            this.card_number = this.loginList[0].card_number;
                            this.getUserInfo();
                        }
                        else {
                            this.status = 5;
                        }
                        setTimeout(() => {
                            this.status = 0;
                        }, 120000)
                    }
                })
            },
            getImg() {
                this.imgSrc = '';
                this.ident_code = '';
                this.status = 0;
                this.openid = '';
                this.$nextTick(() => {
                    let url = '/admin/v1/wechat/mini_qrcode';
                    let data = {}
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.imgSrc = res.data.data.path;
                            this.ident_code = res.data.data.ident_code;
                            this.startPolling();
                        }
                    })
                })
            },
            startPolling() {
                let url = '/admin/v1/wechat/mini_qrcode/status?ident_code=' + this.ident_code;
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        this.status = res.data.data.status;
                        if (this.status == '0' || this.status == '1') {
                            setTimeout(() => {
                                this.startPolling();
                            }, 2000)
                        }
                        if (this.status == 2) {
                            this.openid = res.data.data.openid;
                            this.getLoginList();
                        }
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getUserInfo() {
                let loadingInstance = ElLoading.service()
                let url = '/admin/v1/user_info?openid=' + this.openid + '&card_number=' + this.card_number;
                businessGet(url).then(res => {
                    setTimeout(() => {
                        loadingInstance.close()
                        if (res.data.status == 200) {
                            localStorage.setItem(Global.GlobalLocalName2, JSON.stringify(res.data.data))
                            this.$router.push('/frontPage')
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    }, 500)
                })
            }
        }
    }
</script>

<style scoped>

    .login {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: rgba(65, 148, 242, 1);
        position: relative;
    }

    .loginIn {
        width: 1146px;
        height: 582px;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
    }

    .loginInImg {
        width: 510px;
        position: absolute;
        top: 126px;
        left: 82px;
    }

    #login_container {
        position: absolute;
        top: 50%;
        right: 100px;
        transform: translateY(-50%);
    }

    .login_container_title {
        width: 100%;
        height: 32px;
        box-sizing: border-box;
        line-height: 32px;
        color: #373737;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    .login_container_img {
        width: 282px;
        height: 282px;
        border: 1px solid #E2E2E2;
        margin-top: 14px;
    }

    .login_container_msg {
        width: 100%;
        box-sizing: border-box;
        padding: 7px;
    }

    .login_container_text {
        width: 100%;
        height: 22px;
        box-sizing: border-box;
        line-height: 22px;
        font-size: 13px;
        color: #373737;
        text-align: center;
    }

    .image-slot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }

    .box-card {
        width: 300px;
    }

</style>
